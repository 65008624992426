<template>
		<div class="p-fluid">
			<div class="formgrid grid">
				<div class="field col-12 md:col-6 lg:col-3">
					<label>Area</label>
					<Dropdown id="provinsi_id" dataKey="provinsi_id" v-model="filters.provinsi_id" :loading="loadingDropdownProvinsi"
						:options="dataDropdownProvinsi"
						optionLabel="provinsi_name" optionValue="provinsi_id" placeholder="Pilih Area"
						:filter="true" :showClear="false" @filter="searchDropdownProvinsi($event, 'filter')"
						/>
				</div>
			</div>
		</div>
        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center mb-2">
            <div>
                <Button type="button" :label="'Total '+this.total_visit" icon="pi pi-users" class="p-button-primary"/>
            </div>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
                <Button icon="pi pi-refresh" :loading="loading" class="p-button-warning" @click="showMarker()"/>
            </span>
        </div>
         <BlockUI :blocked="loading">
            <div class="map" id="outletregister_map"></div>
        </BlockUI>
</template>

<script>

// eslint-disable-next-line no-undef
var markers=  L.markerClusterGroup();

export default {
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownProvinsi: false,

            // dataDropdown
            dataDropdownProvinsi: null,

            // filter
            filters: {
                provinsi_id: null,
            },
            
            map: null,
            zoom: 5,
            lat: -3.375126556326247,
            lng: 118.84902874210889,
            location_list: [],
            total_visit: 0
        }
    },
    mounted() {
        // eslint-disable-next-line no-undef
        this.map = L.map('outletregister_map', {
            attributionControl: false,
            doubleClickZoom: true,
            zoomControl: true,
            zoom:1,
            zoomAnimation:false,
            fadeAnimation:true,
            markerZoomAnimation:true
        }).setView([this.lat, this.lng], this.zoom);

        // eslint-disable-next-line no-undef
        L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
            attribution: '© OpenStreetMap'
        }).addTo(this.map);

        // this.showLegend();
        
        this.filters.provinsi_id = 1;
    },
    created(){
        // this.showMarker();
        this.searchDropdownProvinsi('');
    },
    watch: {
        filters: {
            handler() {
                setTimeout(() => {
                    this.showMarker() // Panggil metode yang ingin dijalankan
                }, 500);
            },
            deep: true
        }
    },
    methods:{
        // DROPDOWN
        searchDropdownProvinsi(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dprov.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownProvinsi = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/area',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownProvinsi = res.data.data;
                            this.loadingDropdownProvinsi = false;
                        } else if (purpose == null) {
                            this.dataDropdownProvinsi = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        // MAPS
        showMarker(){

            this.loading= true;

            this.location_list = [];

            markers.clearLayers();
            
            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/dashboard/maps-outlet-register',
                params: {
                    "provinsi_id": this.filters.provinsi_id,
                }
            })
            .then(res => {
                this.location_list = res.data.data;
                this.total_visit = res.data.data.length;

                // eslint-disable-next-line no-unused-vars
                this.location_list.map(function(value, key) {
                    if(value.outlet_latitude != null && value.outlet_longitude != null){

                        var htmlcontent = "<b>Provinsi</b> : " + value.provinsi_name + "</b><br/>";
                            htmlcontent += "<b>Kode Outlet</b> : " + value.outlet_code + "<br/>";
                            htmlcontent += "<b>Nama Outlet</b> : " + value.outlet_name + "<br/>";

                        // eslint-disable-next-line no-undef
                        var marker = L.marker([value.outlet_latitude, value.outlet_longitude]);


                        // eslint-disable-next-line no-unused-vars
                        var popup = marker.bindPopup(htmlcontent);
                        markers.addLayer(marker);
                    }
                }); 
                
                this.map.addLayer(markers);

                this.loading= false;
            })
            .catch((err) => {
                this.loading= false;
                console.log(err);
            });
        },
        // async showMarker() {
        //     this.loading = true;

        //     markers.clearLayers();

        //     try {
        //         const response = await this.axios.get(process.env.VUE_APP_ROOT_API + 'web/dashboard/maps-outlet-register', {
        //             params: {
        //                 "provinsi_id": this.filters.provinsi_id,
        //             }
        //         });

        //         this.location_list = response.data.data;
        //         this.total_visit = response.data.data.length;

        //         this.location_list.forEach(function(value) {
        //             if (value.outlet_latitude != null && value.outlet_longitude != null) {
        //                 var htmlcontent = "<b>Provinsi</b> : " + value.provinsi_name + "</b><br/>";
        //                 htmlcontent += "<b>Kode Outlet</b> : " + value.outlet_code + "<br/>";
        //                 htmlcontent += "<b>Nama Outlet</b> : " + value.outlet_name + "<br/>";

        //                 // eslint-disable-next-line no-undef
        //                 var marker = L.marker([value.outlet_latitude, value.outlet_longitude]);
        //                 // eslint-disable-next-line no-unused-vars
        //                 var popup = marker.bindPopup(htmlcontent);

        //                 markers.addLayer(marker);
        //             }
        //         });

        //         this.map.addLayer(markers);
        //         this.loading = false;
        //     } catch (error) {
        //         this.loading = false;
        //         console.log(error);
        //     }
        // },
        // showLegend() {
        //     setTimeout(() => {
        //         // eslint-disable-next-line no-undef
        //         var legend = L.control({ position: "bottomleft" });

        //         legend.onAdd = function () {

        //             // eslint-disable-next-line no-undef
        //             var div = L.DomUtil.create("div", "legend");
        //             div.innerHTML += "<h4>Keterangan</h4>";
        //             div.innerHTML += '<i style="background: red"></i><span>Sudah Visit</span><br>';
        //             div.innerHTML += '<i style="background: black"></i><span>Belum Visit</span><br>';

        //             return div;
        //         };

        //         legend.addTo(this.map);
        //     }, 250);
        // },
    }
}
</script>

<style>
	.map {
		height: 710px;
        width: 100%;
	}
    /*Legend specific*/
    .legend {
        padding: 6px 8px;
        font: 14px Arial, Helvetica, sans-serif;
        background: white;
        background: rgba(255, 255, 255, 0.8);
        /*box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);*/
        /*border-radius: 5px;*/
        line-height: 24px;
        color: #555;
    }

    .legend h4 {
        text-align: center;
        font-size: 16px;
        margin: 2px 12px 8px;
        color: #777;
    }

    .legend span {
        position: relative;
        bottom: 3px;
    }

    .legend i {
        width: 18px;
        height: 18px;
        float: left;
        margin: 0 8px 0 0;
        opacity: 0.7;
    }

    .legend i.icon {
        background-size: 18px;
        background-color: rgba(255, 255, 255, 1);
    }
</style>
