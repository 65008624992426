<template>
        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center mb-2">
            <div>
                <Button type="button" :label="'Total '+this.total_visit" icon="pi pi-users" class="p-button-primary"/>
            </div>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
                <Button icon="pi pi-refresh" :loading="loading" class="p-button-warning" @click="showMarker()"/>
            </span>
        </div>
         <BlockUI :blocked="loading">
            <div class="map" id="callplan_map"></div>
        </BlockUI>
</template>

<script>

// eslint-disable-next-line no-undef
var markers=  L.markerClusterGroup();

export default {
    props: [
		"filter",
    ],
    data() {
        return {
            loading: false,
            map: null,
            zoom: 5,
            lat: -3.375126556326247,
            lng: 118.84902874210889,
            location_list: [],
            total_visit: 0
        }
    },
    mounted() {
        // eslint-disable-next-line no-undef
        this.map = L.map('callplan_map', {
            attributionControl: false,
            doubleClickZoom: true,
            zoomControl: true,
            zoom:1,
            zoomAnimation:false,
            fadeAnimation:true,
            markerZoomAnimation:true
        }).setView([this.lat, this.lng], this.zoom);

        // eslint-disable-next-line no-undef
        L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
            attribution: '© OpenStreetMap'
        }).addTo(this.map);

        this.showLegend();
    },
    created(){
        this.showMarker();
    },
    watch: {
        filter: {
            handler() {
                setTimeout(() => {
                    this.showMarker() // Panggil metode yang ingin dijalankan
                }, 500);
            },
            deep: true
        }
    },
    methods:{
        showMarker(){

            this.loading= true;

            markers.clearLayers();
            
            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/dashboard/maps-callplan-today',
                params: {
                    "provinsi_id": this.filter.provinsi_id,
                    "homebase_id": this.filter.homebase_id,
                    "userid": this.filter.userid,
                }
            })
            .then(res => {
                this.location_list = res.data.data;
                this.total_visit = res.data.data.length;

                // eslint-disable-next-line no-unused-vars
                this.location_list.map(function(value, key) {
                    if(value.outlet_latitude != null && value.outlet_longitude != null){

                        var htmlcontent = "<b>Provinsi</b> : " + value.provinsi_name + "</b><br/>";
                            htmlcontent += "<b>Kode Outlet</b> : " + value.outlet_code + "<br/>";
                            htmlcontent += "<b>Nama Outlet</b> : " + value.outlet_name + "<br/>";
                            htmlcontent += "<b>Status Visit</b> : " + value.status_visit + "<br/>";

                        var iconUrl = '';

                        if (value.status_visit == "visit") {
                            iconUrl = "/marker/marker-type-outlet-lainnya-beat.png";
                        } else if(value.status_visit == "ec"){
                            iconUrl = "/marker/marker-ec.png";
                        }else {
                            iconUrl = "/marker/marker-type-outlet-lainnya.png";
                        }
                        
                        // eslint-disable-next-line no-undef
                        var icon = L.icon({
                            iconUrl: iconUrl,
                            outlet_id: value.outlet_id,
                            iconSize: [21, 36], // size of the icon
                            // shadowSize:   [50, 64], // size of the shadow
                            iconAnchor: [22, 40], // point of the icon which will correspond to marker's location
                            shadowAnchor: [4, 62], // the same for the shadow
                            popupAnchor: [-10, -46], // point from which the popup should open relative to the iconAnchor
                        })


                        // eslint-disable-next-line no-undef
                        var marker = L.marker([value.outlet_latitude, value.outlet_longitude],
                            {
                                icon: icon,
                            });



                        // eslint-disable-next-line no-unused-vars
                        var popup = marker.bindPopup(htmlcontent);
                        markers.addLayer(marker);
                    }
                }); 
                
                this.map.addLayer(markers);

                this.loading= false;
            })
            .catch((err) => {
                this.loading= false;
                console.log(err);
            });
        },
        showLegend() {
            setTimeout(() => {
                // eslint-disable-next-line no-undef
                var legend = L.control({ position: "bottomleft" });

                legend.onAdd = function () {

                    // eslint-disable-next-line no-undef
                    var div = L.DomUtil.create("div", "legend");
                    div.innerHTML += "<h4>Keterangan</h4>";
                    div.innerHTML += '<i style="background: red"></i><span>Visit</span><br>';
                    div.innerHTML += '<i style="background: black"></i><span>Not Visit</span><br>';
                    div.innerHTML += '<i style="background: green"></i><span>EC</span><br>';

                    return div;
                };

                legend.addTo(this.map);
            }, 250);
        },
    }
}
</script>

<style>
	.map {
		height: 710px;
        width: 100%;
	}
    /*Legend specific*/
    .legend {
        padding: 6px 8px;
        font: 14px Arial, Helvetica, sans-serif;
        background: white;
        background: rgba(255, 255, 255, 0.8);
        /*box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);*/
        /*border-radius: 5px;*/
        line-height: 24px;
        color: #555;
    }

    .legend h4 {
        text-align: center;
        font-size: 16px;
        margin: 2px 12px 8px;
        color: #777;
    }

    .legend span {
        position: relative;
        bottom: 3px;
    }

    .legend i {
        width: 18px;
        height: 18px;
        float: left;
        margin: 0 8px 0 0;
        opacity: 0.7;
    }

    .legend i.icon {
        background-size: 18px;
        background-color: rgba(255, 255, 255, 1);
    }
</style>
