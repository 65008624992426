<template>
    <BlockUI :blocked="loading">
         <Badge :value="percentage" class="mr-2" size="large" severity="primary"></Badge>
        <Chart type="bar" :data="data" :options="options"/>
    </BlockUI>
</template>

<script>

export default {
    props: [
		"filter",
		"startDate",
		"endDate",
    ],
    data() {
        return {
            // loading
            loading: false,

            percentage: null,
            data: null,
            options: {
                indexAxis: 'y',
                elements: {
                    bar: {
                        borderWidth: 2,
                    }
                },
                responsive: true,
                plugins: {
                    legend: {
                        position: 'bottom',
                        display: false,
                    },
                    // tooltip: {
                    //     enabled: false,
                    //     external: function(context) {
                    //         // Tooltip Element
                    //         let tooltipEl = document.getElementById('chartjs-tooltip');

                    //         // Create element on first render
                    //         if (!tooltipEl) {
                    //         tooltipEl = document.createElement('div');
                    //         tooltipEl.id = 'chartjs-tooltip';
                    //         tooltipEl.className = 'external-tooltip';
                    //         tooltipEl.innerHTML = '<table></table>';
                    //         document.body.appendChild(tooltipEl);
                    //         }

                    //         // Hide if no tooltip
                    //         const tooltipModel = context.tooltip;
                    //         // console.log(tooltipModel);
                    //         if (tooltipModel.opacity === 0) {
                    //         tooltipEl.style.opacity = 0;
                    //         return;
                    //         }

                    //         // Set caret Position
                    //         tooltipEl.classList.remove('above', 'below', 'no-transform');
                    //         if (tooltipModel.yAlign) {
                    //         tooltipEl.classList.add(tooltipModel.yAlign);
                    //         } else {
                    //         tooltipEl.classList.add('no-transform');
                    //         }

                    //         function getBody(bodyItem) {
                    //         return bodyItem.lines;
                    //         }

                    //         // Set Text
                    //         if (tooltipModel.body) {
                    //         const titleLines = tooltipModel.title || [];
                    //         const bodyLines = tooltipModel.body.map(getBody);

                    //         let innerHtml = '';

                    //         if (titleLines.length > 0) {
                    //             innerHtml += '<div class="tooltip-title">' + titleLines[0] + '</div>';
                    //         }

                    //         innerHtml += '<div class="tooltip-body"><table>';

                    //         bodyLines.forEach(function(body, i) {
                    //             const colors = tooltipModel.labelColors[i];
                    //             const index = tooltipModel.dataPoints[0].dataIndex;
                    //             const label = tooltipModel.dataPoints[0].dataset.tooltipData[index];
                                
                    //             let style = 'background:' + colors.backgroundColor;
                    //             style += '; border-color:' + colors.borderColor;
                    //             style += '; border-width: 2px';
                    //             const span = '<span style="' + style + '"></span>';
                    //             innerHtml += '<tr><td>' + span + body + '</td></tr>';
                    //             innerHtml += '<tr><td>' + span + label + '</td></tr>';
                    //         });

                    //         innerHtml += '</table></div>';

                    //         tooltipEl.innerHTML = innerHtml;
                    //         }

                    //         const position = context.chart.canvas.getBoundingClientRect();
                    //         // Display, position, and set styles for font
                    //         tooltipEl.style.opacity = 1;
                    //         tooltipEl.style.position = 'absolute';
                    //         tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                    //         tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                    //         tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
                    //         tooltipEl.style.pointerEvents = 'none';
                    //     }
                    // }
                }
            },
        }
    },
	mounted() {
        this.getData();
	},
    computed:{
    },
    watch: {
        filter: {
            handler() {
                setTimeout(() => {
                    this.getData();
                }, 500);
            },
            deep: true
        },
        endDate: {
            handler() {
                setTimeout(() => {
                    if(
                        this.endDate !== null && 
                        this.endDate !== 'Invalid date'
                    ){  
                        this.getData();
                    }
                }, 500);
            },
        }
    },
	methods: {
        getData(){

            this.loading= true;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/dashboard/chart-sales-target',
                params: {
                    "provinsi_id": this.filter.provinsi_id,
                    "homebase_id": this.filter.homebase_id,
                    "userid": this.filter.userid,
                    "tanggal_mulai": this.startDate,
                    "tanggal_akhir": this.endDate,
                }
            })
            .then(res => {
                this.data = res.data.data;
                this.percentage =  res.data.data.datasets[0].persen;
                this.loading= false;
                
            })
            .catch((err) => {
                this.loading= false;
                console.log(err);
            });
        },
	},
}
</script>

<style>
#chartjs-tooltip{
    background: #ddd;
    padding: 8px
}
.external-tooltip {
  position: absolute;
  font-family: Arial, sans-serif;
  font-size: 12px;
  z-index: 9999;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
}

.external-tooltip table {
  width: 100%;
  margin: 0;
  padding: 0;
  border-collapse: collapse;
}

.external-tooltip th,
.external-tooltip td {
  padding: 6px 8px;
  text-align: left;
}

.external-tooltip th {
  background-color: #333;
  color: #fff;
  font-weight: bold;
}

.external-tooltip td {
  background-color: #fff;
  border-top: 1px solid #ddd;
}

.external-tooltip td span {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 6px;
  vertical-align: middle;
}

.external-tooltip .tooltip-title {
  font-weight: bold;
  margin-bottom: 6px;
}

.external-tooltip .tooltip-body {
  margin-top: 6px;
}
</style>