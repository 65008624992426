<template>
	<Error :errors="errors"/>
	<div class="grid">
		<div class="col-12 lg:col-12 xl:col-12">
			<div class="surface-section px-4 py-8 md:px-6 lg:px-8">
				<div class="text-700 text-center">
					<div class="text-blue-600 font-bold mb-3">&nbsp;SELAMAT DATANG DI</div>
					<div class="text-900 font-bold text-5xl mb-3">Web Report - {{appsName}}</div>
				</div>
			</div>
		</div>
	</div>

	<div class="card ">
		<h5>Filter</h5>
		<hr>
		<div class="p-fluid">
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label for="period">Periode</label>
                    <Calendar selectionMode="range"  v-tooltip.top="'Maksimal 60 hari kebelakang'" :manualInput="false" v-model="period" :minDate="minDate" :maxDate="maxDate" dateFormat="dd/mm/yy" icon="pi pi-calendar" :showIcon="true"/>
                </div>
            </div>
			<div class="formgrid grid">
				<div class="field col-12 md:col-6 lg:col-3">
					<label>Area</label>
					<Dropdown id="provinsi_id" dataKey="provinsi_id" v-model="filters.provinsi_id" :loading="loadingDropdownProvinsi"
						:options="dataDropdownProvinsi"
						optionLabel="provinsi_name" optionValue="provinsi_id" placeholder="Pilih Area"
						:filter="true" :showClear="true" @filter="searchDropdownProvinsi($event, 'filter')"
						@change="changeHomebase()" />
				</div>
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Homebase</label>
                    <Dropdown id="homebase_id"  dataKey="homebase_id" v-model="filters.homebase_id" :loading="loadingDropdownHomebase"
                        :options="dataDropdownHomebase"
                        optionLabel="homebase_name" optionValue="homebase_id" placeholder="Pilih Homebase"
                        :filter="true" :showClear="true" @filter="searchDropdownHomebase($event, 'filter')"
                        @change="changeSalesman()"/>
                </div>
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Salesman</label>
                    <MultiSelect id="salesman_id" dataKey="userid" v-model="userids" :loading="loadingDropdownSalesman"
                        :options="dataDropdownSalesman"
                        optionLabel="label" placeholder="Pilih Salesman" :filter="true" :showToggleAll="false"
                        :showClear="true" @filter="searchDropdownSalesman($event, 'filter')"/>
                </div>
			</div>
		</div>
        <Button label="Clear Filter" icon="pi pi-times" class="p-button-warning mr-2 my-1" @click="clearFilter"/>
	</div>

	<div class="grid">
		<div class="col-12 md:col-6 lg:col-3">
			<UserAktif :filter="filters"/>
        </div>
		<div class="col-12 md:col-6 lg:col-3">
			<CallPlan :filter="filters"/>
        </div>
		<div class="col-12 md:col-6 lg:col-3">
			<EC :filter="filters"/>
        </div>
		<div class="col-12 md:col-6 lg:col-3">
			<Sales :filter="filters"/>
        </div>
	</div>

	<div class="grid">
		<div class="col-12 md:col-6 lg:col-6">
			<div class="card ">
				<h4>ACTUAL VS TARGET SALES</h4>
				<SalesTargetChart :filter="filters" :startDate="period_start_label" :endDate="period_end_label"/>
			</div>
        </div>
		<div class="col-12 md:col-6 lg:col-6">
			<div class="card ">
				<h4>ACTUAL VS TARGET SALES % (DAILY)</h4>
				<SalesTarget60DaysChart :filter="filters" :startDate="period_start_label" :endDate="period_end_label"/>
			</div>
        </div>
	</div>

	<div class="grid">
		<div class="col-12 md:col-6 lg:col-6">
			<div class="card ">
				<h4>ACTUAL VS TARGET CALL</h4>
				<CallTargetChart :filter="filters" :startDate="period_start_label" :endDate="period_end_label"/>
			</div>
        </div>
		<div class="col-12 md:col-6 lg:col-6">
			<div class="card ">
				<h4>EC PRODUCT VS ACTUAL CALL</h4>
				<ECProdukAktualChart :filter="filters" :startDate="period_start_label" :endDate="period_end_label"/>
			</div>
        </div>
	</div>

	<div class="grid">
		<div class="col-12">
			<div class="card ">
				<h4>MAPS - CALL PLAN</h4>
				<CallPlanMap :filter="filters"/>
			</div>
		</div>
	</div>

	<!-- <div class="grid">
		<div class="col-12">
			<div class="card ">
				<h4>MAPS - CALL PLAN (ROUTE)</h4>
				<CallPlanRouteMap :filter="filters"/>
			</div>
		</div>
	</div> -->

	<!-- <div class="grid">
		<div class="col-12">
			<div class="card ">
				<h4>MAPS - CHOROPLET</h4>
				<SalesChoropletMap/>
			</div>
		</div>
	</div> -->

	<div class="grid">
		<div class="col-12">
			<div class="card ">
				<h4>MAPS - OUTLET REGISTER</h4>
				<OutletRegisterMap/>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from 'moment';
import CallPlanMap from '../components/dashboard/CallPlanMap.vue';
import OutletRegisterMap from '../components/dashboard/OutletRegisterMap.vue';
import UserAktif from '../components/dashboard/UserAktif.vue';
import CallPlan from '../components/dashboard/CallPlan.vue';
import EC from '../components/dashboard/EC.vue';
import Sales from '../components/dashboard/Sales.vue';
import SalesTargetChart from '../components/dashboard/SalesTargetChart.vue';
import SalesTarget60DaysChart from '../components/dashboard/SalesTarget60DaysChart.vue';
import CallTargetChart from '../components/dashboard/CallTargetChart.vue';
import ECProdukAktualChart from '../components/dashboard/ECProdukAktualChart.vue';
// import SalesChoropletMap from './testing/SalesChoropletMap.vue';
// import CallPlanRouteMap from './testing/CallPlanRouteMap.vue';
import Error from '../components/Error.vue';

export default {
	components: {
		'CallPlanMap': CallPlanMap,
		'OutletRegisterMap': OutletRegisterMap,
		'UserAktif': UserAktif,
		'CallPlan': CallPlan,
		'EC': EC,
		'Sales': Sales,
		'SalesTargetChart': SalesTargetChart,
		'SalesTarget60DaysChart': SalesTarget60DaysChart,
		'CallTargetChart': CallTargetChart,
		'ECProdukAktualChart': ECProdukAktualChart,
		// 'CallPlanRouteMap': CallPlanRouteMap,
		// 'SalesChoropletMap': SalesChoropletMap,
		'Error': Error,
	},
    computed:{
        ...mapGetters(['errors']),
        period_start_label(){ 
            return moment(this.period[0], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
        period_end_label(){ 
            return moment(this.period[1], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
    },
	data() {
		return {		
			appsName: process.env.VUE_APP_APPS_NAME,

            // loading
            loadingDropdownProvinsi: false,
            loadingDropdownHomebase: false,
            loadingDropdownSalesman: false,

            // dataDropdown
            dataDropdownProvinsi: null,
            dataDropdownHomebase: null,
            dataDropdownSalesman: null,

            // filter
            filters: {
                provinsi_id: null,
                homebase_id: null,
                userid: null,
            },
            userids: null,

            period: [new Date(), new Date()],
            maxDate: null,
            minDate: null,
		}
	},
    mounted() {
        this.searchDropdownProvinsi('');
        this.searchDropdownHomebase('');
        this.searchDropdownSalesman('');
        
        //min 60 hari kebelakang
        this.minDate =  new Date();
        this.minDate.setDate(this.minDate.getDate() - 60);
        //max hari ini
        this.maxDate =  new Date();
    },
    created(){
        // set awal tanggal 60 hari kebelakang 
        this.period[0].setDate(this.period[0].getDate() - 60);
    },
    watch: {
        userids() {
            this.filters.userid = this.userids ? this.convertMultiSelectUser(this.userids) : null;
        },
    },
	methods: {
        convertMultiSelectUser(data) {
            if(data){
                return data.map((item) => {
                    return item.userid;
                });
            }
        },
        clearFilter(){
            this.filters.provinsi_id = null;
            this.filters.homebase_id = null;
            this.filters.userid = null;
            this.userids = null;
            this.period= [new Date(), new Date()];

            // set awal tanggal 60 hari kebelakang 
            this.period[0].setDate(this.period[0].getDate() - 60);
        },
        // DROPDOWN
        searchDropdownProvinsi(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dprov.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownProvinsi = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/area',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownProvinsi = res.data.data;
                            this.loadingDropdownProvinsi = false;
                        } else if (purpose == null) {
                            this.dataDropdownProvinsi = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        changeHomebase() {
            this.filters.homebase_id = null;
            this.dataDropdownHomebase = null;
            this.searchDropdownHomebase('');

            this.filters.userid = null;
            this.dataDropdownSalesman = null;
            this.searchDropdownSalesman('');
        },
        searchDropdownHomebase(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dhomebase.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownHomebase = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/homebase',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                        "provinsi_id": this.filters.provinsi_id,
                    }
                })
                .then(res => {

                    if (purpose == "filter") {
                        this.dataDropdownHomebase = res.data.data;
                        this.loadingDropdownHomebase = false;
                    } else if (purpose == null) {
                        this.dataDropdownHomebase = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        changeSalesman() {
            this.filters.userid = null;
            this.dataDropdownSalesman = null;
            this.searchDropdownSalesman('');
        },
        searchDropdownSalesman(event, purpose, valueEdit) {
            setTimeout(() => {
                if (valueEdit) {
                    this.$refs.dsalesman.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownSalesman = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/salesmanv2',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                        "provinsi_id": this.filters.provinsi_id,
                        "homebase_id": this.filters.homebase_id,
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownSalesman = res.data.data;
                            this.loadingDropdownSalesman = false;

                            //Multiple Selected
                            if(this.userids){
                                this.dataDropdownSalesman.push(...this.userids);

                                const uniqueids= [];
                                this.dataDropdownSalesman = this.dataDropdownSalesman.filter((c) => {
                                    const isDup = uniqueids.includes(c.userid);
                                    if(!isDup){
                                        uniqueids.push(c.userid);
                                        return true;
                                    } 
                                    return false;
                                });
                            }

                        } else if (purpose == null) {
                            this.dataDropdownSalesman = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
	}
}
</script>